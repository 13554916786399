import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import {
  LinearProgress,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  productImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
}));
export default function SimilarProductImage() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);
  const productSelected = state.customerProduct.productSelected;
  const [dataSource, setDataSource] = useState([]);
  const [productSelectedImageUrl, setProductSelectedImageUrl] = useState('');
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/similar-pricing-tool/products-included`;
      const postData = {
        customerProductID: productSelected
          ? productSelected.id
          : 0,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        setDataSource(result.data.data);
        setProductSelectedImageUrl(result.data.data[0].image_url)
        // console.log(result.data.data[0].image_url)
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected]);
  return (
    <>
      {isLoading ? (
        <img
          className={classes.productImage}
          alt={productSelected.name}
          src={
            `https://bettercart.ca/resources/images/no-picture-available.png`
          }
        />
      ) : (
        <img
          className={classes.productImage}
          alt={productSelected.name}
          src={
            productSelectedImageUrl
              ? productSelectedImageUrl
              : `https://bettercart.ca/resources/images/no-picture-available.png`
          }
        />
      )}
    </>
  );
}
