import React, { useState ,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../Dialog/DialogCustom";
import ProductSearchBox from "./ProductSearchBox";
import ShowStoreCountColumn from "./ShowStoreCountColumn";
// import SearchBox from "./SearchBox";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
import LoadMore from "./LoadMore";
import { multiSearchAnd } from "../../utility/utility";
import axios from "axios";
import envConfig from "../../bin/env.config";
import VizSensor from "react-visibility-sensor";
import { useSelector } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles((theme) => ({
  dialogContent:{
    paddingTop:'0px'
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
  productImage: {
    width: 50,
    height: 50,
    // marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    // cursor: "pointer",
  },
  productNameCell: {
    textTransform: "capitalize",
    color: "inherit",
    // "&:hover": {
    //   cursor: "pointer",
    //   backgroundColor: theme.palette.secondary.main,
    //   color: "#fff",
    // },
  },
  priceRecordNumber:{
    textAlign:"right"
  }
  
}));
export function CompletedNamePrint({product}){
  // console.log(product);
  // var completeName = product.name.toLowerCase();//.includes(product.brandName.toLowerCase()) ? product.name : product.brandName + " " + product.name
  // completeName = completeName.toLowerCase().includes(product.size.toLowerCase() ) ? completeName : completeName + " " + product.size
  return product.name; //'completeName';
  // return product.upc+" "+product.name//'completeName';
};
export function NumberOfStoresHaveLoading({product,storesSelected}) {
  
  const [isVisible, setIsVisible] = useState(false);
  const [numberOfStores, setNumberOfStores] = useState('-');
  const state = useSelector(state => state);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  useEffect(() => {
    const fetchData = async () => {      
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/product/number-of-stores-have`;
      const postData = {
        productMatchingGroupID : product.id, 
        storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
      };
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
      const numberOfStoresDataLoad = result.data.data;   
      setNumberOfStores(numberOfStoresDataLoad);     
    };
    isVisible&& fetchData();    
  }, [isVisible, product]);
  return (    
        <VizSensor onChange={doLoadData}>
          <div>
          {numberOfStores==='-'?(
            <LinearProgress color="secondary"/>
          ):(
            numberOfStores
          )}
          </div>
        </VizSensor>      
  );
}
export function TableBodyData({dataSource, limit, doLoadMore, handleSelected ,storesSelected, user, showStoreCountColumn}){
  const classes = useStyles();
  
  return (
    <>
      {dataSource.length === 0 && (
        <TableRow>
          <TableCell colSpan={4}>Didn’t find what you’re looking for?</TableCell>
        </TableRow>
      )}
      {dataSource.length > 0 &&
        dataSource.slice(0, limit).map((row, i) => {
          // let isChecked = selected.id === row.id;
          return (
            <TableRow
              key={i}
              onClick={() => handleSelected(i)}
              className={classes.tableRow}
            >
              {user&&user.user_type_id===1 &&
                (<TableCell className={classes.priceRecordNumber}>
                {row.id}
                </TableCell>)
               }
             
             
              <TableCell className={classes.productNameCell}>
                <CompletedNamePrint product={row}/>
              </TableCell>
              <TableCell className={classes.productNameCell}>
                <span style={{whiteSpace:"nowrap"}}>{row.size}</span>
              </TableCell>
              <TableCell className={classes.productNameCell}>
              <span style={{whiteSpace:"nowrap"}}>{row.brandName}</span>
              </TableCell>
              {showStoreCountColumn&&(
                <TableCell className={classes.priceRecordNumber}>
                  <NumberOfStoresHaveLoading product={row} storesSelected={storesSelected} />
                </TableCell>
              )}
            </TableRow>                    
          );
        })}
        {dataSource.length > limit&&(
              <LoadMore doLoadMore={doLoadMore} />
        )}
      </>
  )
}
export default function SelectOneDialog({
  items,
  setItemSelected,
  open,
  handleClose,
  storesSelected, 
  title
}) {
  const classes = useStyles();
  // const [selected, setSelected] = useState(itemSelected);

  // const menus = useSelector((state) => state.menu.menus);
  // const userMenus = menus.reduce((a, b) => ((a[b] = true), a), {});
  const state = useSelector(state => state);
  const user = state.session.user;
  const showStoreCountColumn = state.myProduct.showStoreCountColumn;
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [dataSource, setDataSource] = useState(items);
  const [searchTerm, setSearchTerm] = useState("");
 
  const handleSelected = (selectedIndex) => {
    setItemSelected(dataSource[selectedIndex]);
    handleClose();
  };
  const handleCloseClick = () => {
    handleClose();
    setSkip(0);
    setLimit(50);
    setDataSource(items);
  };
  useEffect(() => {
    setDataSource(items)
  }, [items]);
  const doSearch = (q, categoryId) => {
    //doSearch
    // setSearchTerm(q);
    // console.log(items)
    let itemsTemp = []
    if(categoryId>0&&q!=""){
      setDataSource(items.filter(
                      (item) =>
                                (multiSearchAnd(item.id.toString(),q) ||
                                multiSearchAnd(item.upc,q) ||
                                multiSearchAnd(item.name,q) ||
                                multiSearchAnd(item.brandName,q))&&(item.category_id===categoryId)
                  )) 
    }else if(categoryId>0&&q===""){
      setDataSource(
          items.filter(
            (item) =>(item.category_id===categoryId)
          )
      )
    }else if(categoryId===0&&q!=""){
      setDataSource(
        items.filter(
          (item) =>
                  (multiSearchAnd(item.id.toString(),q) ||
                  multiSearchAnd(item.upc,q) ||
                  multiSearchAnd(item.name,q) ||
                  multiSearchAnd(item.brandName,q))
        )
      )
    }else if(categoryId===0&&q===""){
      setDataSource(items)
    }
  };
 
  const doLoadMore = () => {
    let tempLimit = limit+50
    setLimit(tempLimit);
  };
  
  return (   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleCloseClick}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title" >
          <ProductSearchBox
            placeholder="Search a product"
            value={searchTerm}
            doSearch={doSearch}
            searchOnChange={false}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table size="small">
            <TableHead >
                <TableRow>
                  {user&&user.user_type_id===1&&(<TableCell>#ID</TableCell>)}
                  
                  <TableCell>Product</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Brand</TableCell> 
                  {showStoreCountColumn&&(
                  <TableCell className={classes.priceRecordNumber}>Stores</TableCell>
                  )}
                </TableRow>
            </TableHead>
            <TableBody>
         
              <TableBodyData dataSource={dataSource} limit={limit} doLoadMore={doLoadMore} handleSelected={handleSelected} 
              storesSelected={storesSelected} user={user}
              showStoreCountColumn ={showStoreCountColumn}
              />
          
            </TableBody>
          </Table>
        </DialogContent>
        {user&&user.user_type_id===1&&(
           <DialogActions>
            <ShowStoreCountColumn/>
          </DialogActions> 
        )}
         
        {/* <DialogActions>
          {generatePage()}
        </DialogActions> */}
      </Dialog>    
  );
}
