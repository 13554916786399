import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../bin/env.config.js";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Menu } from "../components/Menu.js";
import Loading from "../components/Loading/Loading.js";
import { useDispatch, useSelector } from "react-redux";
import LineGraph from "../components/Charts/LineGraph.js";
import { PriceComponent } from "../utility/utility.js";
import ToolbarPanel from "../components/ReportTrend/ToolbarPanel.js";
import ProductImage from "../components/Common/ProductImage.js";
import SortLabel from "../components/Common/SortLabel.js";
import {
  selectProduct
} from "../store/actions/productAction.js";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 188px)",
    boxShadow: "none",
    // max-height: 100% !important'; and display: inline-block !important
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    // cursor: "pointer",
    height:"130px"    
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px',
    whiteSpace: 'nowrap'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px',
    whiteSpace: 'nowrap'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    maxHeight: "50px",
    // maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    marginLeft:'auto',
    marginRight:'auto'
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width:'350px',
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
}));


export function ProductRow({row,index}){
  const classes = useStyles();  
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const handleRowClick =(row)=>{
    let selectedProduct= state.product.products.find(x =>x.id==row.id)
    // console.log(selectedProduct)
    dispatch(selectProduct(selectedProduct))
    history.push(`/single-report/`)    
  }
  return (
    <>
    {row.start_avg>0 &&(
          <TableRow hover key={index} className={classes.tableRow} >
          <TableCell align="left"  title={row.label}>
            {/* <ProductImages product={row}/> */}
            <ProductImage product_matching_group_id={row.id} title={row.name} width={'50px'} height={'50px'} company_logo_width={'20px'}/>
          </TableCell>
          <TableCell align="left"  title={row.label}>
          <span style={{display:"none"}}>{row.id} </span>    
          <span style={{cursor:"pointer"}} onClick={()=>handleRowClick(row)}>{row.name} </span>   
          </TableCell>
          <TableCell align="left"  title={row.label}>
              {row.size}        
          </TableCell>  
          <TableCell align="center">
            <div className={classes.lineGraphContainer} >
              <LineGraph
                key={index}
                storeProductPrices={row.prices}
                columnField={'avg_price'}
              />
            </div>
          </TableCell>
          <TableCell align="left"> 
            <PriceComponent value={row.start_avg} />
          </TableCell>                            
          <TableCell align="left"> 
            <PriceComponent value={row.end_avg} />
          </TableCell>  
          <TableCell align="center"> 
            <span>{row.percent_change>0?'+'+row.percent_change:row.percent_change}%</span>
            {/* <span>{Math.round((row.end_avg- row.start_avg)*1000/row.start_avg)/10}%</span> */}
          </TableCell>           
        </TableRow>   
    )}
    </>
     
    )
}
export default function ReportTrendDetail(props) {
  const classes = useStyles();
  const reportId = props.match.params.report_id||1
  const labelId = props.match.params.id
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  
  const [dataSource, setDataSource] = useState({});
  const [dataProducts, setDataProducts] = useState([]);
  const [orderDirection, setOrderDirection] = useState(1);
  
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const postData = {
        fromDate: fromDate,
        toDate: toDate,
        labelId:labelId
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/trend-report/report-detail`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });      
      setDataSource(result.data.data);
      setDataProducts(result.data.data.products)
      setIsLoading(false);
    };
    
    fetchDataSource();
  }, [fromDate,toDate]);  

 
  const handleSort=(column)=>{
    
    setOrderDirection(-orderDirection);
    
    let tempData = [...dataProducts]
    column ==='start_avg'||column =='end_avg'||column =='percent_change'?
    tempData.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempData.sort((a,b) => ((a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0))*orderDirection);
    setDataProducts(tempData)
  }
  return (
    <div className={classes.root}>
      <Menu goBackButtonEnable={true} title={reportId==1?"Brands for":"Segment Trends for"} 
        formDateToDateSelectedButtonEnable 
        // addBrandButtonEnable 
        selectedProvinceEnable
        />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <ToolbarPanel trendReportLabel={dataSource} isLoading={isLoading}/>
          <Divider />
          <Grid container spacing={3}>            
            <Grid item xs={12} md={12} lg={12}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                  style={{background:"white"}}
                >
                 
                  <Table stickyHeader aria-label="brands">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"  className={classes.thHeader} width='150px'></TableCell>
                        <TableCell align="left"  className={classes.thHeader} >
                          <SortLabel onClick={() => handleSort("name")}>
                            Name
                          </SortLabel>
                          </TableCell>
                        <TableCell align="left"  className={classes.thHeader} >Size</TableCell>    
                        <TableCell align="center"  className={classes.thHeader} width='650px'>Price Trend</TableCell>  
                        <TableCell align="left"  className={classes.thHeader} width='120px'>
                          <SortLabel onClick={() => handleSort("start_avg")}>
                            Start Avg $
                          </SortLabel>                          
                        </TableCell>                 
                        <TableCell align="left"  className={classes.thHeader} width='120px'>
                          <SortLabel onClick={() => handleSort("end_avg")}>
                            End Avg $
                          </SortLabel>
                          
                          </TableCell>                 
                                      
                        <TableCell align="center"  className={classes.thHeader} >
                          <SortLabel onClick={() => handleSort("percent_change")}>
                            Price % Change
                          </SortLabel>
                          
                          </TableCell>                                    
                      </TableRow>
                      </TableHead>
                    <TableBody>
                    {dataProducts &&
                      dataProducts.map((row, index) => <ProductRow row={row} index={index}/>)}
                    </TableBody>
                    <TableFooter>
                    
                    </TableFooter>
                  </Table>
                </TableContainer>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        
      </main>      
    </div>
  );
}
